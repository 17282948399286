import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { ErrorBoundary } from "./hoc/ErrorBoundary";
import Routes from "./routes";
import { store } from "./store/store";
import "./assets/less/main-light.less";
// import "./assets/less/main-dark.less";
import { ThemeSwitcherProvider } from "react-css-theme-switcher";
import ScrollToTop from "./hoc/ScrollToTop";
import ThemeLoader from "./hoc/ThemeLoader";
import i18n from "./i18n";
import { I18nextProvider } from "react-i18next";
// import ReactGA from "react-ga";

import ReactGA from "react-ga4";

const trackingId = process.env.REACT_APP_GA_TRACKING_ID as string;

ReactGA.initialize(trackingId);

function App() {
  const themes = {
    light: `${process.env.PUBLIC_URL}/main-light.min.css`,
    dark: `${process.env.PUBLIC_URL}/main-dark.min.css`,
  };

  return (
    <I18nextProvider i18n={i18n}>
      <BrowserRouter>
        <ErrorBoundary>
          <Provider store={store}>
            <ScrollToTop>
              {process.env.NODE_ENV === "development" ? (
                <ThemeSwitcherProvider themeMap={{}}>
                  <ThemeLoader>
                    <Routes />
                  </ThemeLoader>
                </ThemeSwitcherProvider>
              ) : (
                <ThemeSwitcherProvider defaultTheme="light" themeMap={themes}>
                  <ThemeLoader>
                    <Routes />
                  </ThemeLoader>
                </ThemeSwitcherProvider>
              )}
            </ScrollToTop>
          </Provider>
        </ErrorBoundary>
      </BrowserRouter>
    </I18nextProvider>
  );
}

export default App;
