export enum LocalStorageKeys {
  ACCESS_TOKEN = "accessToken",
  REFRESH_TOKEN = "refreshToken",
  THEME = "theme",
}

export const appName = "HEI";

export enum PAYMENT_TYPE {
  ESEWA = "esewa",
  KHALTI = "khalti",
  CARD_PAYMENT = "cardPayment",
  CONNECTIPS = "connectIps",
}

export enum POLICY {
  MOTORCYCLE = "MCY",
  ELECTRIC_MOTORCYCLE = "EMCY",
  PRIVATE_VEHICLE = "PV",
  TRAVEL = "TI",
  PERSONAL_ACCIDENT = "PA",
  HOME = "HOME",
  AGRICULTURE = "CTL",
  PUBLIC_HEALTH_INSURANCE = "PHI",
  INTERNATIONAL_TRAVEL_INSURANCE = "ITI",
  OTHERS = "OTHER",
}

export const KHALTI_PUBLIC_KEY =
  "test_public_key_784aa04bf002475b8fb1ee8f80fa8d75";

export const ESEWA_BASE_URL = "https://uat.esewa.com.np/epay/main";
export const ESEWA_SUCCESS_URL = "http://localhost:3000?q=su";
export const ESEWA_FAILURE_URL = "http://localhost:3000?q=fu";

export enum POLICY_STATUS {
  DRAFT = "draft",
  PAYMENT_PENDING = "pending_payment",
  PAID = "paid",
  PENDING_APPROVAL = "pending_approval",
  SENT_FOR_APPROVAL = "sent_for_approval",
  APPROVED = "approved",
  REJECTED = "rejected",
  PENDING_PAYMENT_AFTER_APPROVAL = "pending_payment_after_approval",
  RENEWED = "renewed",
}

export const statusClass = {
  approved: "success",
  rejected: "error",
  draft: "warning",
  paid: "processing",
  pending_payment: "geekblue",
  pending_approval: "magenta",
  sent_for_approval: "magenta",
  waiting_for_approval: "blue",
  pending_payment_after_approval: "geekblue",
  pending: "magenta",
  generated: "geekblue",
  verified: "success",
  intimated: "success",
  processed: "processing",
  registered: "geekblue",
  cancelled: "error",
  claim_approved: "success",
  assessment: "purple",
  withdraw: "error",
  discarded: "error",
  expired: "error"
};

export const statusToPreview = {
  draft: "Draft",
  pending_payment: "Pending Payment",
  paid: "Paid",
  pending_approval: "Pending Approval",
  sent_for_approval: "Pending Approval",
  approved: "Created",
  rejected: "Rejected",
  pending_payment_after_approval: "Pending Payment",
  pending: "Pending",
  generated: "Draft",
  verified: "Verified",
  processed: "Processed",
  expired: "Expired",
  claims: {
    draft: "Draft",
    registered: "Registered",
    cancelled: "Cancelled",
    assessment: "Assessment",
    intimated: "Intimated",
    waiting_for_approval: "Claimed",
    approved: "Claim Approved",
    paid: "Paid",
    discarded: "Rejected",
    withdraw: "Rejected",
    rejected: "Rejected",
  },
};

export const aliasMap = {
  MCY: "motorcycle",
  EMCY: "electric-motorcycle",
  PV: "private-vehicle",
  TI: "travel",
  ITI: "travel",
  PHI: "personal-health",
  HOME: "home",
  PA: "personal-accident",
  CTL: "agriculture",
  FSH: "agriculture",
};
